import React, { useState, useEffect, useContext } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import ResourceCard from '@system/resource-card'
import PageContext from '@context'
import getSortedStories from '@helpers/get-sorted-stories'
import get from 'lodash/get'
import formatLocalizedLink from '@helpers/format-localized-link'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles((theme) => ({
  displayCards: {
    display: 'flex',
    flexDirection: 'column',
    padding: (props) => (props.isInfoGraphicModule ? '15px 0px' : '25px 8px'),
    textAlign: (props) =>
      props.alignment === 'left' ? '' : `${props.alignment}`,
    width: (props) => (props.isInfoGraphicModule ? '100%' : '33.33333333%'),
    [theme.breakpoints.down('sm')]: {
      width: '50% !important',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  },
  title: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '15px',
    display: 'block',
  },
  description: {
    color: theme.palette.text.tertiary,
    paddingBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '15px',
    },
    display: 'block',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: (props) => props.isVideoModule && '0px 60px',
  },
}))

const ResourceReferences = (props) => {
  const isRelatedContent = props.isRelatedContent
  const isVideoModule = props.isVideoModule

  const classes = useStyles({
    ...props.blok,
    isRelatedContent,
    isInfoGraphicModule: props.isInfoGraphicModule,
    isVideoModule,
  })

  const pageContext = useContext(PageContext)

  const {
    sortBy,
    topic,
    workflow,
    constructionPhase,
    format,
    companyType,
    theme,
    filters,
    isReferencingAllPublishedResources,
    numberOfReferences,
    resources,
  } = props.blok

  const broadensResults = 'broadensResults'
  const narrowsResults = 'narrowsResults'
  const alphabetical = 'alphabetical'
  const chronological = 'chronological'
  const reverseChronological = 'reverse-chronological'
  const topics = 'topic'
  const workflows = 'workflow'
  const companyTypes = 'companyType'
  const constructionPhases = 'constructionPhase'
  const formats = 'format'
  const themes = 'theme'
  const filteredBroadensResults = []
  const filteredNarrowsResults = []

  const previousIndexAssets =
    props.filteredAssets &&
    props.filteredAssets
      .filter((assets) => assets.index <= props.index - 1)
      .reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue.assets),
        []
      )

  const currentIndexAssets =
    props.filteredAssets &&
    props.filteredAssets.filter((assets) => assets.index === props.index)

  const filterNarrowsResults = props.filterLogicRules
    ? props.filterLogicRules.filter(
        (filter) => filter.filterResultLogic === narrowsResults
      )
    : filters.filter(
        (filter) =>
          filter.filterResultsLogic === narrowsResults &&
          filter.options.reduce((acc, value) => acc).fieldOptions.length > 0
      )

  const filterBroadensResults = props.filterLogicRules
    ? props.filterLogicRules.filter(
        (filter) => filter.filterResultLogic === broadensResults
      )
    : filters.filter(
        (filter) =>
          filter.filterResultsLogic === broadensResults &&
          filter.options.reduce((acc, value) => acc).fieldOptions.length > 0
      )

  const allPublishedResources =
    !props.shouldDisplayAllResources &&
    !!previousIndexAssets &&
    previousIndexAssets.length > 0
      ? !!pageContext.resources &&
        pageContext.resources
          .filter(
            (resource) =>
              !previousIndexAssets.some(
                (assets) => assets.title === resource.content.title
              )
          )
          .map((resource) => ({ ...resource, ...resource.content }))
      : !!pageContext.resources &&
        pageContext.resources
          .map((resource) => ({ ...resource, ...resource.content }))
          .reverse()

  const allNonPublishedResources =
    !props.shouldDisplayAllResources &&
    !!previousIndexAssets &&
    previousIndexAssets.length > 0
      ? resources
          .filter(
            (resource) =>
              !previousIndexAssets.some(
                (assets) => assets.title === resource.content.title
              )
          )
          .map((resource) => ({ ...resource, ...resource.content }))
      : resources &&
        resources
          .map((resource) => ({
            ...resource,
            ...resource.content,
          }))
          .reverse()

  const allResources = isReferencingAllPublishedResources
    ? allPublishedResources
    : allNonPublishedResources

  const fetchResourceFilterOptions = (filterOptions) => {
    const resourceFilterData = filters.filter(
      (filter) => filter.filterDataPath === filterOptions
    )
    const filtersFieldOption = get(
      resourceFilterData,
      '[0].options.[0].fieldOptions'
    )
    return resourceFilterData.length > 0 ? filtersFieldOption : []
  }

  const getFormattedFilterOptions = (filtersName) =>
    filters
      ? fetchResourceFilterOptions(filtersName)
      : filtersName === ''
      ? []
      : typeof filtersName === 'string'
      ? [filtersName]
      : filtersName

  const resourceTopics = filters ? fetchResourceFilterOptions(topics) : topic

  const resourceWorkflow = filters
    ? fetchResourceFilterOptions(workflows)
    : workflow

  const resourceFormat = getFormattedFilterOptions(format)
  const resourceCompany = getFormattedFilterOptions(companyType)

  const resourceConstruction = filters
    ? fetchResourceFilterOptions(constructionPhases)
    : constructionPhase

  const resourceTheme = filters ? fetchResourceFilterOptions(themes) : theme

  const isSortedResources =
    sortBy === alphabetical ||
    sortBy === chronological ||
    sortBy === reverseChronological

  let allMatched = false
  let matchedResources = false
  let pushResources = false

  const fetchMultipleValueLogic = (value) => {
    const multiValueLogic = filters
      ? filters.some(
          (multiValueLogic) =>
            multiValueLogic.filterDataPath === value &&
            multiValueLogic.multipleValuesLogic === narrowsResults
        )
      : props.filterLogicRules.some(
          (multiValueLogic) =>
            multiValueLogic.filterDataPath === value &&
            multiValueLogic.multiValueLogic === narrowsResults
        )
    return multiValueLogic ? narrowsResults : broadensResults
  }

  const fetchListOfMatchedResources = (
    filterOptions,
    resourceOptions,
    filteredBroadensResults,
    multipleValuesLogic,
    resource
  ) => {
    return multipleValuesLogic === narrowsResults
      ? filterOptions.forEach((filterOption, index) => {
          if (
            resourceOptions &&
            resourceOptions.length > 0 &&
            resourceOptions.indexOf(filterOption) !== -1
          ) {
            if (
              filterOptions.length - 1 === index &&
              !allMatched &&
              !pushResources &&
              resource
            ) {
              filteredBroadensResults.push(resource)
              allMatched = true
            } else {
              matchedResources = true
            }
          } else {
            pushResources = true
          }
        })
      : filterOptions.forEach((filterOption) =>
          resourceOptions &&
          resourceOptions.length > 0 &&
          resourceOptions.indexOf(filterOption) > -1 &&
          !matchedResources &&
          resource
            ? (filteredBroadensResults.push(resource),
              (matchedResources = true))
            : (matchedResources = true)
        )
  }

  const [filteredResources, setFilteredResources] = useState([])

  const resourcesContent =
    pageContext.resources &&
    pageContext.resources.map((resource) => ({
      ...resource,
      ...resource.content,
    }))

  const webinarsContent =
    pageContext.webinars &&
    pageContext.webinars.map((webinar) => ({ ...webinar, ...webinar.content }))

  useEffect(() => {
    const resourcesAndWebinarsContent = [
      ...(resourcesContent ?? []),
      ...(webinarsContent ?? []),
    ]
    if (props?.searchValue) {
      const searchFilteredResources =
        resourcesAndWebinarsContent &&
        resourcesAndWebinarsContent.filter(
          (data) =>
            data.title &&
            data.title
              .toLowerCase()
              .indexOf(props.searchValue.toLowerCase()) !== -1
        )
      setFilteredResources(searchFilteredResources)
    } else {
      loadFilteredResources(allResources)
    }
  }, [
    topic,
    sortBy,
    workflow,
    constructionPhase,
    format,
    companyType,
    props.searchValue,
    pageContext.resources,
  ])

  const filterMatchedResources = (
    filterResult,
    category,
    resourceCategory,
    resourceOption,
    filteredArray,
    story
  ) => {
    const hasMatched = hasFilterOptionMatched(filterResult, category)
    if (hasMatched) {
      const multipleValues = fetchMultipleValueLogic(category)
      fetchListOfMatchedResources(
        resourceCategory,
        resourceOption,
        filteredArray,
        multipleValues,
        story
      )
    }
  }

  const hasFilterOptionMatched = (filterResults, option) =>
    filterResults.some((filterResult) => filterResult.filterDataPath === option)

  const fetchBroadensResult = (filterBroadensResources) => {
    filterBroadensResources &&
      filterBroadensResources.forEach((resource) => {
        if (resourceTopics && resourceTopics.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            topics,
            resourceTopics,
            resource.topics,
            filteredBroadensResults,
            resource
          )
          pushResources = false
        }

        if (resourceWorkflow && resourceWorkflow.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            workflows,
            resourceWorkflow,
            resource.workflow,
            filteredBroadensResults,
            resource
          )
          pushResources = false
        }

        if (resourceConstruction && resourceConstruction.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            constructionPhases,
            resourceConstruction,
            resource.constructionPhase,
            filteredBroadensResults,
            resource
          )
          pushResources = false
        }

        if (resourceFormat && resourceFormat.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            formats,
            resourceFormat,
            [resource.format],
            filteredBroadensResults,
            resource
          )
          pushResources = false
        }

        if (resourceCompany && resourceCompany.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            companyTypes,
            resourceCompany,
            resource.companyType,
            filteredBroadensResults,
            resource
          )
          pushResources = false
        }

        if (resourceTheme && resourceTheme.length > 0) {
          filterMatchedResources(
            filterBroadensResults,
            themes,
            resourceTheme,
            resource.theme,
            filteredBroadensResults,
            resource
          )
          pushResources = false
        }
        matchedResources = false
        allMatched = false
      })
  }

  const fetchNarrowsResult = (filterNarrowsResources) => {
    filterNarrowsResources.forEach((resource) => {
      if (resourceTopics && resourceTopics.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          topics,
          resourceTopics,
          resource.topics,
          filteredNarrowsResults
        )
      }

      if (resourceWorkflow && resourceWorkflow.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          workflows,
          resourceWorkflow,
          resource.workflow,
          filteredNarrowsResults
        )
      }

      if (resourceConstruction && resourceConstruction.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          constructionPhases,
          resourceConstruction,
          resource.constructionPhase,
          filteredNarrowsResults
        )
      }

      if (resourceFormat && resourceFormat.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          formats,
          resourceFormat,
          [resource.format],
          filteredNarrowsResults
        )
      }

      if (resourceCompany && resourceCompany.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          companyTypes,
          resourceCompany,
          resource.companyType,
          filteredNarrowsResults
        )
      }

      if (resourceTheme && resourceTheme.length > 0) {
        filterMatchedResources(
          filterNarrowsResults,
          themes,
          resourceTheme,
          resource.theme,
          filteredNarrowsResults
        )
      }

      matchedResources && filteredNarrowsResults.push(resource)
      matchedResources = false
    })
  }

  const loadFilteredResources = (resourceStories) => {
    filterBroadensResults &&
      filterBroadensResults.length > 0 &&
      fetchBroadensResult(resourceStories)

    const allBroadensResources =
      filterBroadensResults && filterBroadensResults.length > 0
        ? filteredBroadensResults
        : resourceStories

    filterNarrowsResults &&
      filterNarrowsResults.length > 0 &&
      allBroadensResources &&
      fetchNarrowsResult(allBroadensResources)

    const sortedResources =
      filterNarrowsResults.length > 0 || filterBroadensResults.length > 0
        ? filterNarrowsResults.length > 0
          ? filteredNarrowsResults.length > 0 && isSortedResources
            ? getSortedStories(
                filteredNarrowsResults,
                sortBy,
                pageContext.resources
              )
            : filteredNarrowsResults
          : isSortedResources
          ? getSortedStories(
              filteredBroadensResults,
              sortBy,
              pageContext.resources
            )
          : filteredBroadensResults
        : getSortedStories(resourceStories, sortBy, pageContext.resources)

    setFilteredResources(
      sortBy === reverseChronological
        ? sortedResources.reverse()
        : sortedResources
    )
  }

  const isDuplicateAssets =
    !props.shouldDisplayAllResources &&
    props.filteredResources &&
    props.index > props.filteredAssets[props.filteredAssets.length - 1].index &&
    filteredResources.length > 0

  isDuplicateAssets && props.filterDuplicateAsset(filteredResources)

  const currentIndexAssetsArray = get(currentIndexAssets, '[0].assets')
  const getNumberOfReferences = props.isRelatedContent ? 1 : numberOfReferences

  const allResourcesOnIndexView =
    (filteredResources.length > 0 &&
      filteredResources
        .filter((resources) => resources._uid !== props._uid)
        .slice(0, getNumberOfReferences)) ||
    (currentIndexAssetsArray &&
      currentIndexAssetsArray
        .filter((currentIndexAsset) => currentIndexAsset._uid !== props._uid)
        .slice(0, getNumberOfReferences))

  const allResourcesWithoutIndexView =
    (filteredResources.length > 0 && filteredResources) ||
    currentIndexAssetsArray

  const filteredResourcesList = props.shouldDisplayAllResources
    ? allResourcesWithoutIndexView
    : allResourcesOnIndexView

  const surfaceRegionalStories = (a, b) => {
    return a.isLocalizedStory ? -1 : 1
  }

  const renderResourceCards = () => {
    const sortedFilteredResourcesList = filteredResourcesList.sort(
      surfaceRegionalStories
    )

    return sortedFilteredResourcesList.map((resource) => {
      const resourceBlokData = {
        alignment: 'left',
        description: resource.subtitle,
        image: resource.image,
        noDescMargin: false,
        notVisibleOnMobile: false,
        title: resource.title,
        resourceReference: true,
        buttonAssetUrl: resource.buttonAssetUrl,
        format: resource.format,
        link: formatLocalizedLink(resource.full_slug),
      }

      return (
        <Box
          key={resource.full_slug}
          className={classNames(classes.displayCards)}
        >
          <ResourceCard
            blok={resourceBlokData}
            imgMaxWidth={imgMaxWidths.resourceCard}
          />
        </Box>
      )
    })
  }

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.cardContainer}>
        {filteredResourcesList ? renderResourceCards() : null}
      </Box>
    </SbEditable>
  )
}

export default ResourceReferences
